import React, { FC, useCallback } from 'react';
import { BeveledContainer } from '../../../components/BeveledContainer';
import { DepositType } from '../../../dto';
import { depositCalculationsStore } from '../../../store/depositCalculationsStore';
import { observer } from 'mobx-react';
import { Select } from '../../../components/Select';

type Props = {
    disabled?: boolean;
};

export const SelectDepositType: FC<Props> = observer(({ disabled = false }) => {
    const onChange = useCallback((value: DepositType) => {
        depositCalculationsStore.setDepositType(value);
    }, []);

    return (
        <BeveledContainer>
            <Select<DepositType>
                label="Выберите продукт"
                name={'depositType'}
                items={depositCalculationsStore.depositTypes}
                renderValue={item => item.name}
                keyGetter={item => item.id}
                error={!!depositCalculationsStore.error}
                helperText={depositCalculationsStore.error}
                variant="standard"
                value={depositCalculationsStore.depositType}
                onChange={onChange}
                readOnly={disabled}
                required
            />
        </BeveledContainer>
    );
});
