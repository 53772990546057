import React, { FC } from 'react';
import { Divider, styled } from '@mui/material';
import { formatCurrency, formatPercent } from '../../utils/formatters';
import { Text } from '../../components/Text';
import { Colors } from '../../theme/colors';
import { getDepositStatusText } from '../../utils/deposits';
import { DepositInfo } from '../../dto';

type Props = {
    deposit: DepositInfo;
    onClick: () => void;
};

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '14px',
    cursor: 'pointer',
});

const Content = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '9px',
});

const Image = styled('img')({
    maxWidth: '24px',
    maxHeight: '24px',
});

const Info = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '3px',
});

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

const Circle = styled('div')({
    maxWidth: '32px',
    maxHeight: '32px',
    minWidth: '32px',
    minHeight: '32px',
    backgroundColor: Colors.lightestBlue,
    borderRadius: '16px 16px 16px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const DepositItem: FC<Props> = ({ deposit, onClick }) => {
    return (
        <Root onClick={onClick}>
            <Content>
                <Circle>
                    <Image src={`data:image/jpeg;base64,${deposit.typeIcon}`} />
                </Circle>
                <Info>
                    <Row>
                        <Text variant="Small" color="secondary">
                            {getDepositStatusText(deposit)}
                        </Text>
                        <Text variant="Small" color="secondary">
                            {formatPercent(deposit.profitPercent)}
                        </Text>
                    </Row>
                    <Row>
                        <Text variant="Body">{deposit.name}</Text>
                        <Text variant="Body">{formatCurrency(deposit.sumTotal)}</Text>
                    </Row>
                </Info>
            </Content>
            <Divider color={Colors.lightBlue} />
        </Root>
    );
};
