import { useRequest } from '../../hooks/useRequest';
import { depositTypesRequest } from '../../store/depositTypesRequest';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { depositCalculationsStore } from '../../store/depositCalculationsStore';
import { Box, Button, CircularProgress, LinearProgress, styled } from '@mui/material';
import { Layout } from '../../components/Layout';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { CreateDepositOptions } from '../common/CreateDepositOptions';
import { Text } from '../../components/Text';
import { createDepositRequest } from './createDepositRequest';
import { getDepositInput } from '../../dto/DepositInputDto';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { authStore } from '../../store/authStore';
import { UserStatus } from '../../types';
import { CreateDepositNadFinishRegistration } from './CreateDepositPageAndFinishRegistration';

const Root = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

const Actions = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
});

export const CreateDepositPage = observer(() => {
    const { data, loading } = useRequest(depositTypesRequest, {});
    const { user } = authStore;

    const history = useHistory();

    useEffect(() => {
        depositCalculationsStore.init(data || []);
    }, [data]);

    const onCreate = useCallback(() => {
        createDepositRequest
            .request(
                getDepositInput({
                    depositId: depositCalculationsStore.depositType?.id || '0',
                    duration: depositCalculationsStore.duration || 0,
                    firstPayment: depositCalculationsStore.sum || 0,
                })
            )
            .then(() => history.push('/'));
    }, [history]);

    const isCreating = createDepositRequest.isLoading;

    if (!user) return <CircularProgress />;

    if (user.status === UserStatus.DRAFT) {
        return (
            <CreateDepositNadFinishRegistration />
        )
    }

    return (
        <Layout title={'Новый продукт'}>
            {(loading || isCreating) && <LinearProgress />}
            {data && (
                <Root>
                    <Breadcrumbs paths={[{ title: 'инвестиции', link: '/' }, { title: 'вклад' }]} />
                    <Text variant="LargeTitle">Новый продукт</Text>
                    <CreateDepositOptions disabled={isCreating} />
                    <Actions>
                        <Button
                            onClick={onCreate}
                            disabled={!!depositCalculationsStore.error || isCreating}
                            color="primary"
                            variant="contained"
                        >
                            Создать
                        </Button>
                    </Actions>
                </Root>
            )}
        </Layout>
    );
});
